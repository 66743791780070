































import Vue from "vue";

import {
  navLinks,
  bottomNavLinks,
  bottomHelpLinks,
  helpLinks,
} from "./nav-links";

export default Vue.extend<any, any, any, any>({
  name: "Drawer",
  components: {
    DrawerHeader: () =>
      import(
        /* webpackChunkName: "drawer-header" */
        "./DrawerHeader.vue"
      ),
    HelpDrawerHeader: () =>
      import(
        /* webpackChunkName: "help-drawer-header" */
        "./HelpDrawerHeader.vue"
      ),
    List: () =>
      import(
        /* webpackChunkName: "list" */
        "./List.vue"
      ),
  },
  data: () => ({
    dark: true,
    navLinks,
    bottomNavLinks,
    bottomHelpLinks,
    helpLinks,
    links: ["Home"],
    gradient: "rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)",
    drawerImage: false,
    image:
      "https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg",
  }),
  computed: {
    mini: {
      get() {
        return this.$store.getters.mini;
      },
      set() {
        this.$store.dispatch("toggleMini");
      },
    },
    drawer: {
      get() {
        return this.$store.getters.drawer;
      },
      set(val) {
        this.$store.dispatch("setDrawer", val);
      },
    },
    isHelp(): boolean {
      return this.$route.meta?.help || false;
    },
  },
  // computed: {
  //   ...get("user", ["dark", "gradient", "image"]),
  //   ...get("app", ["items", "version"]),
  //   ...sync("app", ["drawer", "drawerImage", "mini"]),
  // },
  methods: {
    toggleMini(mini: boolean) {
      this.$store.dispatch("toggleMini", mini);
    },
  },
  mounted() {
    const drawer = document.getElementById("drawer") as HTMLElement;

    if (!drawer) return;

    drawer.onmouseover = () => {
      const overrideMini = !!localStorage.getItem("override_mini");
      if (overrideMini) this.toggleMini(false);
    };

    drawer.onmouseleave = () => {
      const overrideMini = !!localStorage.getItem("override_mini");
      if (overrideMini) this.toggleMini(true);
    };
  },
});
